import {
	useState,
	useEffect,
	useLayoutEffect,
	useRef,
} from 'react'
import {
	Flex,
	Box,
	Text,
	Spinner,
	useColorModeValue,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useRecoilState, useRecoilValue } from 'recoil'
// import axios from 'axios'
import { gsap } from 'gsap'

import {
	blockState,
	scrubBlockState,
	usdRatiosState,
	scrubUsdRatiosState,
	priceState,
	scrubPriceState,
	scrubbingState,
	staticModeState,
	circlesBreakpointState,
	showBlockRippleState,
	confModeState,
	confBgImgState,
} from '../../../state'
import { BitcoinSymbolEntity, Label } from '../../shared'
import { PriceWrap, Price } from './Corners.components'
import { useAppSelector } from '../../../hooks'
import {
	getMoscowTime,
	abbreviateMoscowTime,
	abbreviateNumber,
	decimalPlaces,
	getSupplyIssued,
	getBreakpointValue,
} from '../../../utils'
import {
	blurInAnimation,
	EXCHANGE_RATE_USDBTC_LABEL,
	TIMECHAIN_CALENDAR_PRICE_FEED_URL,
} from '../../../constants'
import { secondaryDataFontSize } from '../../features/calendar/data-components/dataComponents.constants'

export const CornerNE = () => {
	const exchangeRef = useRef<HTMLDivElement>(null)
	const usdRef = useRef<HTMLDivElement>(null)
	const moscowTimeRef = useRef<HTMLDivElement>(null)
	const marketRef = useRef<HTMLDivElement>(null)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const scrubPrice = useRecoilValue(scrubPriceState)
	const scrubbing = useRecoilValue(scrubbingState)
	const block = useRecoilValue(blockState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const staticMode = useRecoilValue(staticModeState)
	const [price, setPrice] = useRecoilState(priceState)
	const [moscowTime, setMoscowTime] = useState(0)
	const [usdRatios, setUsdRatios] = useRecoilState(usdRatiosState)
	const scrubUsdRatios = useRecoilValue(scrubUsdRatiosState)
	const confMode = useRecoilValue(confModeState)
	const confBgImg = useRecoilValue(confBgImgState)
	const [reconnectCounter, setReconnectCounter] = useState<number | undefined>(0)
	// const [statusColor, setStatusColor] = useState('red100')
	const {
		showMoscowTime,
		showCuckBucks,
		showMarketCap,
	} = useAppSelector(({ settings }) => settings)
	const { userCurrency } = useAppSelector(({ currencies }) => currencies)
	const formattedUserCurrency = userCurrency.toUpperCase()
	const isUserConverted = userCurrency !== 'usd'

	const conversionRatio = isUserConverted && usdRatios[formattedUserCurrency] !== undefined
		? usdRatios[formattedUserCurrency]
		: 1
	const scrubConversionRatio = isUserConverted && scrubUsdRatios[userCurrency] !== undefined
		? scrubUsdRatios[userCurrency]
		: 1
	const showUsdScrub = isUserConverted && scrubUsdRatios[userCurrency] === undefined
	const finalUserCurrency = showUsdScrub ? 'USD' : formattedUserCurrency
	const convertedMoscowTime = Number(conversionRatio) >= 1
		? (moscowTime / Number(conversionRatio)) >= 100
			?  Math.floor(moscowTime / Number(conversionRatio))
			: (moscowTime / Number(conversionRatio)).toFixed(2)
		: Math.floor(moscowTime / Number(conversionRatio))
	const usdPrice = String(Math.floor(price * Number(conversionRatio)))
	const formattedPrice = Number(usdPrice) >= 1000000
		? abbreviateMoscowTime(Number(usdPrice))
		: usdPrice
	const scrubPriceValue = scrubPrice
		? scrubPrice < 1
			? decimalPlaces(scrubPrice * Number(scrubConversionRatio))
			: (scrubPrice * Number(scrubConversionRatio)) > 1000000
				? abbreviateMoscowTime(Math.floor(scrubPrice * Number(scrubConversionRatio)))
				: String(Math.floor(scrubPrice * Number(scrubConversionRatio)))
		: 'N/A'
	const scrubPriceMoscowTime = scrubPrice ? abbreviateMoscowTime(getMoscowTime(scrubPrice * Number(scrubConversionRatio))) : 'N/A'
	const supply = staticMode
		? getSupplyIssued(scrubBlock.height)
		: getSupplyIssued(block.height)
	const marketCap = supply
		? staticMode
			? abbreviateNumber(Math.floor(supply.supplyIssued * (price * Number(scrubConversionRatio))), 2)
			: abbreviateNumber(Math.floor(supply.supplyIssued * (price * Number(conversionRatio))), 2)
		: 0
	const scrubMarketCap = supply && scrubPrice ? abbreviateNumber(supply.supplyIssued * scrubPrice, 1) : 0
	const both = showMoscowTime && showCuckBucks
	const loading = scrubbing
		|| (moscowTime === 0 && usdPrice === '0')
		|| (showMoscowTime && !isFinite(moscowTime))
		|| (showMarketCap && marketCap === 0)
	const marketCapFontSize = getBreakpointValue(secondaryDataFontSize, circlesBreakpoint)

	// const ledSize = getBreakpointValue({ base: '6px', md: '8px' }, circlesBreakpoint)
	const ledGap = getBreakpointValue({ base: '2px', md: '6px' }, circlesBreakpoint)
	const responsiveGap = getBreakpointValue({ base: '7px', md: 3, xxxl: 4 }, circlesBreakpoint)
	const bitcoinOnlyFontSize = getBreakpointValue({
		base: 'sm',
		xxs: 'md',
		xs: '22px',
		sm: '27px',
		md: '30px',
		lg: '34px',
		xxl: '40px',
		xxxl: '44px',
		jumbo: '50px',
	}, circlesBreakpoint)
	const spinnerMt = getBreakpointValue({ base: 1, md: 2 }, circlesBreakpoint)
	const marketCapMt = getBreakpointValue({ base: '5px', md: '6px', lg: '7px' }, circlesBreakpoint)
	const opacity = scrubbing || showBlockRipple ? 0.21 : 1
	const filter = scrubbing || showBlockRipple ? 'blur(4px)' : 'blur(0)'
	const color = useColorModeValue('black', 'white')
	const color2 = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const titleMb = getBreakpointValue({ base: '3px', md: '2px', jumbo: 0 }, circlesBreakpoint)
	const isConfBackground = confMode && confBgImg

	const BitcoinOnly = () => {
		return (
			<Flex direction="column">
				<Flex
					align="flex-end"
					direction="column"
					pt="2px"
				>
					<Text
						fontSize={bitcoinOnlyFontSize}
						fontWeight="semibold"
						lineHeight="none"
					>
						1<span style={{ color: color2, fontSize: '90%' }}><BitcoinSymbolEntity /></span> = 1<span style={{ color: color2, fontSize: '90%' }}><BitcoinSymbolEntity /></span>
					</Text>
				</Flex>
			</Flex>
		)
	}

	useEffect(() => {
		const newMoscowTime = getMoscowTime(price)
		setMoscowTime(newMoscowTime)
	}, [price])

	useEffect(() => {
		const pingInterval = 45000
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let socket: any
		const maxReconnectAttempts = 4

		// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars, @typescript-eslint/no-explicit-any
		const handleSocketOpen = (event: any) => {
			console.info('[exchange] open socket')
			setReconnectCounter(Number(reconnectCounter) + 1)
			const pingTimer = setInterval(() => {
				socket.send(JSON.stringify({ type: 'ping' }))
			}, pingInterval)

			return () => clearInterval(pingTimer)
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const handleSocketMessage = (event: any) => {
			const data = JSON.parse(event.data)
			const {
				btc,
				// maxbtc,
				forex,
			} = data

			if (event.data === JSON.stringify({ type: 'pong' })) {
				return
			}

			if (btc) {
				setPrice(btc)
				setUsdRatios(forex)                                                                                                                                 
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const handleSocketClose = (event: any) => {
			if (!event.wasClean) {
				// setStatusColor('red100')
				console.info(`[exchange] close socket: ${event.code}`)
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const handleSocketError = (event: any) => {
			console.error('[exchange] error:', event)
			socket.close()
			attemptReconnect()
		}

		const attemptReconnect = () => {
			setReconnectCounter((prevCounter) => {
				if (Number(prevCounter) < maxReconnectAttempts) {
					console.info(`[exchange] reconnect attempt ${Number(prevCounter) + 1} of ${maxReconnectAttempts}`)
					setTimeout(() => {
						connectWebSocket()
					}, 1000)
					return Number(prevCounter) + 1
				} else {
					console.error('[exchange] Max reconnection attempts reached. Attempting secondary source.')
				}
			})
		}

		const connectWebSocket = () => {
			socket = new WebSocket(TIMECHAIN_CALENDAR_PRICE_FEED_URL)

			socket.addEventListener('open', handleSocketOpen)
			socket.addEventListener('message', handleSocketMessage)
			socket.addEventListener('close', handleSocketClose)
			socket.addEventListener('error', handleSocketError)
		}

		connectWebSocket()

		return () => {
			socket.removeEventListener('open', handleSocketOpen)
			socket.removeEventListener('message', handleSocketMessage)
			socket.removeEventListener('close', handleSocketClose)
			socket.removeEventListener('error', handleSocketError)
		}
	}, [])

	useLayoutEffect(() => {
		setTimeout(() => {
			const ctx = gsap.context(() => {
				const tl = gsap.timeline()
				if (usdRef.current) {
					tl.to(usdRef.current, {
						opacity: 1,
						duration: 1,
						ease: 'easeInOut',
					})
				}
				if (moscowTimeRef.current) {
					tl.to(moscowTimeRef.current, {
						opacity: 1,
						duration: 1,
						delay: 0.3,
						ease: 'easeInOut',
					}, '<')
				}
				if (marketRef.current) {
					tl.to(marketRef.current, {
						opacity: 1,
						duration: 1,
						delay: 0.3,
						ease: 'easeInOut',
					}, '<')
				}
			}, exchangeRef)

			return () => ctx.revert()
		}, 100)
	})

	return (
		<Flex
			className="tc-corner-ne"
			ref={exchangeRef}
			pos="absolute"
			top={0}
			right={0}
			direction="column"
			align="flex-end"
			transition="all 0.45s ease"
			color={color}
			opacity={opacity}
			filter={filter}
			animation={blurInAnimation}
		>
			{isConfBackground && (
				<Box
					pos="absolute"
					w="162px"
					top="-20px"
					right="-20px"
					bottom="-20px"
					bg="rgba(0,0,0,0.7)"
					filter="blur(20px)"
					zIndex={-1}
				/>
			)}

			<Flex gap={ledGap} align="center" mb={titleMb}>
				{/* <Box
					h={ledSize}
					w={ledSize}
					mt="-1px"
					borderRadius="50%"
					bg={statusColor}
					transition="all 0.21s ease"
				/> */}

				<Label color={color}>
					<FormattedMessage id="ne_corner.exchange_rate" />
				</Label>
			</Flex>

			{loading && (
				<>
					{!scrubbing && (
						<BitcoinOnly />
					)}
					
					<Spinner
						color={color}
						size="lg"
						mt={spinnerMt}
					/>
				</>
			)}	

			{staticMode && (
				<>
					{!loading && (
						<Flex
							direction="column"
							gap={responsiveGap}
						>
							{showCuckBucks && (
								<Flex
									ref={usdRef}
									justify="flex-end"
									opacity={0}
								>
									<PriceWrap
										both={both}
										price={scrubPriceValue}
									>
										<Price both={both}>
											<Label
												color={color2}
												fontWeight="semibold"
												mt="2px"
											>
												{finalUserCurrency} {EXCHANGE_RATE_USDBTC_LABEL}
											</Label>
										</Price>
									</PriceWrap>
								</Flex>
							)}

							{showMoscowTime && (
								<Flex
									ref={moscowTimeRef}
									justify="flex-end"
									opacity={0}
								>
									<PriceWrap both={both} price={scrubPriceMoscowTime}>
										<Price both={both}>
											<Label
												color={color2}
												fontWeight="semibold"
												mt="2px"
											>
												{'SATS / '} {finalUserCurrency}
											</Label>
										</Price>
									</PriceWrap>
								</Flex>
							)}

							{showMarketCap && (
								<Flex
									ref={marketRef}
									opacity={0}
									align="flex-end"
									direction="column"
									mt="7px"
								>
									<Label mb="2px">
										<FormattedMessage id="ne_corner.market" />
									</Label>

									<Text
										fontSize={marketCapFontSize}
										fontWeight="semibold"
										lineHeight="none"
									>
										{scrubMarketCap}
									</Text>

									<Label
										color={color2}
										fontWeight="bold"
										mt="2px"
									>
										{finalUserCurrency}
									</Label>
								</Flex>
							)}
						</Flex>
					)}
				</>
			)}

			{!staticMode && !loading && (
				<Flex direction="column" gap={responsiveGap}>
					{showCuckBucks && isFinite(moscowTime) && (
						<Flex
							ref={usdRef}
							justify="flex-end"
							opacity={0}
						>
							<PriceWrap both={both} price={formattedPrice}>
								<Price both={both}>
									<Label
										color={color2}
										fontWeight="semibold"
										mt="2px"
									>
										{formattedUserCurrency} {EXCHANGE_RATE_USDBTC_LABEL}
									</Label>
								</Price>
							</PriceWrap>
						</Flex>
					)}

					{showMoscowTime && isFinite(moscowTime) && (
						<Flex
							ref={moscowTimeRef}
							justify="flex-end"
							opacity={0}
						>
							<PriceWrap both={both} price={convertedMoscowTime}>
								<Price both={both}>
									<Label
										color={color2}
										fontWeight="semibold"
										mt="2px"
									>
										{'SATS / '} {formattedUserCurrency}
									</Label>
								</Price>
							</PriceWrap>
						</Flex>
					)}

					{!showCuckBucks && !showMoscowTime && !scrubbing && (
						<BitcoinOnly />
					)}

					{showMarketCap && (
						<Flex
							ref={marketRef}
							opacity={0}
							align="flex-end"
							direction="column"
							mt={marketCapMt}
						>
							<Label mb="2px">
								<FormattedMessage id="ne_corner.market" />
							</Label>

							<Text
								fontSize={marketCapFontSize}
								fontWeight="semibold"
								lineHeight="none"
							>
								{marketCap}
							</Text>

							<Label
								color={color2}
								fontWeight="semibold"
								mt="2px"
							>
								{formattedUserCurrency}
							</Label>
						</Flex>
					)}

				</Flex>
			)}
		</Flex>
	)
}
